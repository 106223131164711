import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  mobileScreen: false,
}

export const mediaQuerySlice = createSlice({
  name: 'mediaQuery',
  initialState,
  reducers: {
    setIsMobileScreen: (state, action) => {
      state.mobileScreen = action.payload
    },
  },
})

export const { setIsMobileScreen } = mediaQuerySlice.actions

export default mediaQuerySlice.reducer