import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Select } from '@chakra-ui/react'
import * as moment from 'moment'
import 'moment/locale/es'

import MainLayout from '../components/layout/main-layout'
import PaymentItem from '../components/payments/payment-item'
import { fetchPaymentsByCompany } from '../services/api/payments'

moment.locale('es')

const months = moment
	.months()
	.map(month => month.charAt(0).toUpperCase() + month.slice(1))
const years = Array.from(
	new Array(moment().year() - 2020),
	(val, index) => 2021 + index
)

const PaymentsPage = () => {
	const company = useSelector(({ auth }) => auth.business)
	const [payments, setPayments] = useState([])
	const [month, setMonth] = useState(
		moment().format('MMMM').charAt(0).toUpperCase() +
			moment().format('MMMM').slice(1)
	)
	const [year, setYear] = useState(moment().format('YYYY'))

	const fetchPayments = () => {
		const period = `${month} ${year}`
		const payload = {
			period,
			entity: 'business',
			entity_id: company.id,
		}
		fetchPaymentsByCompany(payload)
			.then(res => {
        const payments = res.data.toSorted((a, b) => new Date(b._created).getTime() - new Date(a._created).getTime())
				setPayments(payments)
			})
			.catch(err => console.error(err))
	}

	useEffect(() => {
		fetchPayments()
	}, [month, year])

	const handleSelectChange = prop => e => {
		if (prop === 'month') {
			setMonth(e.target.value)
		} else {
			setYear(e.target.value)
		}
	}

	return (
		<MainLayout>
			<h1 className='text-2xl font-bold'>Facturación y pagos</h1>
			<h3 className='mb-8'>
				Aquí podrás ver el historial de pagos y facturación de tu negocio.
			</h3>
			<div className='flex flex-row items-center gap-4'>
				<div className='flex flex-col gap-2 flex-1'>
					<label htmlFor='selectMonth' className='font-bold'>
						Mes
					</label>
					<Select
						id='selectMonth'
						value={month}
						onChange={handleSelectChange('month')}
					>
						{months.map(month => (
							<option key={month} value={month}>
								{month}
							</option>
						))}
					</Select>
				</div>
				<div className='flex flex-col gap-2 flex-1'>
					<label htmlFor='selectYear' className='font-bold'>
						Año
					</label>
					<Select
						id='selectYear'
						value={year}
						onChange={handleSelectChange('year')}
					>
						{years.map(year => (
							<option key={year} value={year}>
								{year}
							</option>
						))}
					</Select>
				</div>
			</div>
			<div className='flex flex-col gap-4 mt-8'>
				{payments.map(payment => (
					<PaymentItem payment={payment} key={payment.id} />
				))}
			</div>
			
		</MainLayout>
	)
}

export default PaymentsPage
