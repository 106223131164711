import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, NavLink as Link } from 'react-router-dom'
import { MENU_ITEMS, PARTNER_ITEMS } from '../../config/navigation'
import { toggleExpanded, toggleNavbar } from '../../redux/features/navbarSlice'
import Icon from '../shared/icon'

import './sidebar.css'

const NavHeader = ({ close }) => (
	<header className='sidebar-header'>
		<button className='button close-btn' onClick={close}>
			<Icon icon='close' size='lg' />
		</button>
	</header>
)

const NavButton = ({
	name,
	icon,
	isActive,
	hasSubNav,
	path,
	expanded,
	closeSidebar,
}) => (
	<Link
		to={path}
		className={`sidebar-button button ${isActive ? 'active' : ''}`}
		onClick={closeSidebar}
	>
		{icon && <Icon icon={icon} />}
		{expanded && <span>{name}</span>}
	</Link>
)

const SubMenu = ({ item, activeItem }) => {
	const navRef = useRef(null)
	const isSubNavOpen = (item, items) =>
		items.some(i => i === activeItem) || activeItem === item
	const { name, items } = item

	return (
		<div
			className={`sub-nav ${isSubNavOpen(name, items) ? 'open' : ''}`}
			style={{
				height: !isSubNavOpen(name, items) ? 0 : navRef.current?.clientHeight,
			}}
		>
			<div ref={navRef} className='sub-nav-inner'>
				{item?.items.map((subItem, index) => (
					<NavButton
						name={subItem}
						isActive={activeItem === subItem}
						key={index}
					/>
				))}
			</div>
		</div>
	)
}

export const Sidebar = () => {
	const [activeItem, setActiveItem] = useState('')
	// const [expanded, setExpanded] = useState(false)
	const type = useSelector(({ auth }) => auth.type)
	const open = useSelector(({ navbar }) => navbar.open)
	const mobileScreen = useSelector(({ mediaQuery }) => mediaQuery.mobileScreen)
	const expanded = useSelector(({ navbar }) => navbar.expanded)
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		const { pathname } = location
		setActiveItem(pathname)
	}, [location])

	return (
		<aside
			className={`sidebar ${expanded ? ' expanded' : ''} ${
				open ? ' open' : ''
			}`}
		>
			<NavHeader
				expand={() => dispatch(toggleExpanded())}
				close={() => dispatch(toggleNavbar())}
			/>
			{(type === 'partner' ? PARTNER_ITEMS : MENU_ITEMS).map((item, index) => (
				<div key={item.name}>
					<NavButton
						name={item.name}
						icon={item.icon}
						isActive={activeItem === item.path}
						path={item.path}
						expanded={expanded || (mobileScreen && open)}
						closeSidebar={() => dispatch(toggleNavbar())}
					/>
				</div>
			))}
			<div className='hide-action'>
				<button
					className={`sidebar-button button`}
					onClick={() => dispatch(toggleExpanded())}
				>
					<Icon icon={expanded ? 'step-backward' : 'step-forward'} />
					{expanded && <span>Ocultar</span>}
				</button>
			</div>
		</aside>
	)
}

export const SidebarWithBackdrop = () => {
	const open = useSelector(({ navbar }) => navbar.open)
	const mobileScreen = useSelector(({ mediaQuery }) => mediaQuery.mobileScreen)

	return (
		<div className={`backdrop ${open && mobileScreen ? 'open' : ''}`}>
			<div className='sidebar-wrapper'>
				<Sidebar />
			</div>
		</div>
	)
}
