import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  product: null,
  products: [],
  additions: [],
  productsMap: {},
  additionsMap: {},
}

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    selectProduct: (state, action) => {
      state.product = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
      state.productsMap = action.payload.reduce((acc, product) => {
        acc[product.id] = product
        return acc
      }, {})
    },
    setAdditions: (state, action) => {
      state.additions = action.payload
      state.additionsMap = action.payload.reduce((acc, addition) => {
        acc[addition.id] = addition
        return acc
      }, {})
    },
  },
})

export const { selectProduct, setProducts, setAdditions} = productSlice.actions

export default productSlice.reducer