import axios from 'axios'
import { Buffer } from 'buffer'
import { env } from '../../config/environment'

export const fetchOrdersPag = (companyId, limit, offset) => {
  return axios.get(`${env.apiUrl}/orders/company/pag?company=${companyId}&limit=${limit}&offset=${offset}`)
}

export const fetchOrderReport = (companyId, from, to) => {
  return axios.get(`${env.apiUrl}/orders/reports?ID=${companyId}&from=${from}&to=${to}`)
}

export const fetchOrderStatsByMonth = (companyId, month, year) => {
  return axios.get(`${env.apiUrl}/orders/stats/month?company=${companyId}&month=${month}&year=${year}`)
}

export const setOrderStatus = (orderId, status, manager) => {
  const buffer = Buffer.from(manager)
  const encoded = buffer.toString('base64')
  return axios.put(`${env.apiUrl}/orders/status?ID=${orderId}&status=${status}`, {}, {
    headers: {
      'Order-Manager': encoded,
    }
  })
}

export const setOrderTime = (orderId, minutes) => {
  return axios.put(`${env.apiUrl}/orders/minutes?ID=${orderId}&minutes=${minutes}`)
}

export const setOrderRider = (orderId, riderInfo) => {
  return axios.put(`${env.apiUrl}/orders/rider/set?ID=${orderId}`, riderInfo)
}

export const rejectOrder = (payload) => {
  return axios.post(`${env.apiUrl}/rejections`, payload)
}

export const fetchOrderById = id => {
  return axios.get(`${env.apiUrl}/orders/order?ID=${id}`)
}

export const countOrdersByCityState = payload => {
  return axios.post(`${env.apiUrl}/orders/city-state/month`, payload)
}

export const fetchOrdersByCityState = (payload, limit, offset) => {
  return axios.post(`${env.apiUrl}/orders/city-state?limit=${limit}&offset=${offset}`, payload)
}

export const fetchErrandsByPartner = (partner, limit, offset) => {
  return axios.get(`${env.apiUrl}/errands/partner?limit=${limit}&offset=${offset}&partner=${partner}`)
}

export const setErrandStatus = (errand, status, manager) => {
  const buffer = Buffer.from(manager)
  const encoded = buffer.toString('base64')
  return axios.put(`${env.apiUrl}/errands/status?ID=${errand}&status=${status}`, {}, {
    headers: {
      'Order-Manager': encoded,
    }
  })
}

export const setErrandTime = (errand, minutes) => {
  return axios.put(`${env.apiUrl}/errands/minutes?ID=${errand}&minutes=${minutes}`)
}

export const fetchClipboardData = (order) => {
  return axios.get(`${env.apiUrl}/orders/clipboard?ID=${order}`)
}