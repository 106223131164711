export const appColors = {
  primary: '#f05112',
  secondary: '#0F63A6',
  green: '#5cb85c',
  red: '#d9534f',
  white: '#ffffff',
  black: '#000000',
  gray: '#808080',
  lightgray: '#d3d3d3',
  lightblue: '#5bc0de',
}