import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

import Icon from '../shared/icon'

import { toggleNavbar } from '../../redux/features/navbarSlice'
import { DEFAULT_VALUES } from '../../utils/default'
import { clearLocalAuth } from '../../services/app/sessionStorage'
import { login } from '../../redux/features/authSlice'

const Header = ({ title = '', inner = false }) => {
	const company = useSelector(({ auth }) => auth.business)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleLogout = () => {
		clearLocalAuth()
		dispatch(login(null))
		navigate('/login')
	}

	return (
		<header className='bg-primary-color h-[65px] fixed z-[99] top-0 left-0 right-0 shadow-md flex flex-row items-center justify-between px-8'>
			<img
				src='/assets/img/agus-icon-white.png'
				alt=''
				className='hidden md:block w-[20px]'
			/>
			{!inner && (
				<button
					onClick={() => dispatch(toggleNavbar())}
					className='block md:hidden'
				>
					<Icon icon='bars' size='xl' />
				</button>
			)}
			{inner && (
				<button onClick={() => navigate(-1)} className='block md:hidden'>
					<ChevronLeftIcon color='white' boxSize={8} />
				</button>
			)}
			{inner && title && (
				<p className='text-sm md:text-md lg:text-lg text-white font-bold'>
					{title}
				</p>
			)}
			<Menu>
				<MenuButton>
					<div className='flex flex-row items-center gap-3'>
						<p className='hidden md:block text-sm md:text-md lg:text-lg text-white font-bold'>
							{company.name}
						</p>
						<img
							src={company?.image || DEFAULT_VALUES['square-image']}
							alt=''
							className='w-[32px] h-[32px] rounded-full'
						/>
					</div>
				</MenuButton>
				<MenuList>
					<MenuItem onClick={() => navigate('/information')}>Perfil</MenuItem>
					<MenuItem onClick={handleLogout}>Salir</MenuItem>
				</MenuList>
			</Menu>
		</header>
	)
}

export default Header
