import axios from 'axios'
import { env } from '../../config/environment'

export const fetchAdditions = id => {
  const url = `${env.apiUrl}/additions/company?company=${id}`
  return axios.get(url)
}

export const updateAddition = (id, body) => {
  const url = `${env.apiUrl}/additions?ID=${id}`
  return axios.put(url, body)
}

export const createAddition = body => {
  const url = `${env.apiUrl}/additions`
  return axios.post(url, body)
}
