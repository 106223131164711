import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false,
  expanded: true,
}

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    toggleNavbar: (state) => {
      state.open = !state.open
    },
    toggleExpanded: (state) => {
      state.expanded = !state.expanded
    }
  },
})

export const { toggleNavbar, toggleExpanded } = navbarSlice.actions

export default navbarSlice.reducer