import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  authUser: null,
  business: null,
  type: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.business = action.payload
      state.type = action.payload?.type
    },
    setCompany: (state, action) => {
      state.business = action.payload
    },
  },
})

export const { login, setCompany } = authSlice.actions

export default authSlice.reducer