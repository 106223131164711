import axios from 'axios'
import { env } from '../../config/environment'

export const fetchPartnerByEmail = email => {
	return axios.post(`${env.apiUrl}/partners/email`, { email })
}

export const addDevice = (partnerID, device) => {
	return axios.put(
		`${env.apiUrl}/partners/devices/add?ID=${partnerID}&device=${device}`
	)
}

export const removeDevice = (partnerID, device) => {
	return axios.put(
		`${env.apiUrl}/partners/devices/remove?ID=${partnerID}&device=${device}`
	)
}

export const fetchPaymentsToDo = partnerID => {
	return axios.get(`${env.apiUrl}/partners/payments?ID=${partnerID}`)
}

export const fetchPendingPayments = partnerID => {
	return axios.get(`${env.apiUrl}/partners/payments/pending?ID=${partnerID}`)
}

export const fetchPartners = () => {
	return axios.get(`${env.apiUrl}/partners`)
}

export const updatePartner = (partnerID, partner) => {
	return axios.put(`${env.apiUrl}/partners?ID=${partnerID}`, partner)
}

export const updateSecrets = (partnerID, config) => {
	return axios.put(
		`${env.apiUrl}/partners/config/secrets?ID=${partnerID}`,
		config
	)
}
