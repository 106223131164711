import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'react-responsive-modal'
import * as xlsx from 'xlsx'
import {
	AttachmentIcon,
	CheckIcon,
	CloseIcon,
	DeleteIcon,
} from '@chakra-ui/icons'
import { Button, Spinner, useToast } from '@chakra-ui/react'
import MainLayout from '../components/layout/main-layout'
import { appColors } from '../utils/colors'
import { useNavigate } from 'react-router-dom'
import { createAddition } from '../services/api/additions'

const UploadAdditionsPage = () => {
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const business = useSelector(({ auth }) => auth.business)
	const [loading, setLoading] = useState(false)
	const [additions, setAdditions] = useState([])
	const navigate = useNavigate()
	const toast = useToast()

	const handleChange = evt => {
		const files = evt.target.files,
			f = files[0]
		if (!f) {
			return
		}
		const reader = new FileReader()
		reader.onload = function (e) {
			const data = e.target.result
			let readedData = xlsx.read(data, { type: 'binary' })
			const wsname = readedData.SheetNames[0]
			const ws = readedData.Sheets[wsname]

			let dataParse = xlsx.utils.sheet_to_json(ws, { header: 1 })
			const additions = []
			dataParse.forEach((d, i) => {
				if (i === 0 || d.length <= 0) {
					return
				}
				const body = createBody(d)
				if (body) {
					additions.push(body)
				}
			})
			setAdditions(additions)
		}
		reader.readAsBinaryString(f)
	}

	const createBody = d => {
		const name = d[0]
		const price = d[1]
		const code = d[2]
		if (!name || !price) {
			return null
		}
		return {
			name,
			price,
			company: business.id,
			soldout: false,
			code,
		}
	}

	const createAdditions = () => {
		setShowConfirmModal(false)
		setLoading(true)
		const promises = additions.map(addition => createAddition(addition))
		Promise.all(promises)
			.then(response => {
				toast({
					title: 'Adiciones cargadas',
					description: 'Las adiciones han sido cargadas correctamente.',
					status: 'success',
					duration: 4000,
					isClosable: true,
				})
				clear()
				navigate('/products')
			})
			.catch(error => {
				console.error(error)
				toast({
					title: 'Error',
					description: 'Ha ocurrido un error al cargar las adiciones.',
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const clear = () => {
		setAdditions([])
		document.getElementById('file').value = ''
	}

	return (
		<MainLayout>
			<h2 className='text-2xl text-black mb-5'>Cargar Adiciones</h2>
			<p>Puedes cargar un archivo de excel para cargar tus adiciones.</p>
			<p>
				Puedes descargar el formato para registar tus adiciones{' '}
				<a
					href='https://firebasestorage.googleapis.com/v0/b/agus-companies.appspot.com/o/templates%2Fadditions-template.xlsx?alt=media&token=785bb120-2a77-4576-8cad-84f8bc6dfa39'
					target='_blank'
					rel='noopener noreferrer'
					className='text-blue-400 cursor-pointer'
				>
					aquí
				</a>
			</p>
			<p>
				Los campos obligatorios son <strong>nombre y precio</strong>
			</p>
			<div className='flex flex-col mt-4'>
				<label
					htmlFor='file'
					className='cursor-pointer bg-primary-color text-white text-center py-2 px-4 rounded-lg w-[240px]'
				>
					<AttachmentIcon className='mr-2' />
					Seleccionar archivo
				</label>
				<input
					type='file'
					id='file'
					className='hidden'
					onChange={handleChange}
				/>
			</div>
			<div className='w-full mt-4'>
				{additions.length > 0 && (
					<table className='w-full'>
						<thead>
							<tr>
								<th className='px-1'>
									<div className='bg-[#0af6] rounded-md py-1 text-white'>
										Nombre
									</div>
								</th>
								<th className='px-1'>
									<div className='bg-[#0af6] rounded-md py-1 text-white'>
										Precio
									</div>
								</th>
								<th className='px-1'>
									<div className='bg-[#0af6] rounded-md py-1 text-white'>
										Código
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{additions.map((product, i) => (
								<tr key={i}>
									<td className='max-w-20 px-2 text-ellipsis overflow-hidden'>
										{product.name}
									</td>
									<td className='max-w-20 px-2 text-ellipsis overflow-hidden'>
										{product.price}
									</td>
									<td className='max-w-20 px-2 text-ellipsis overflow-hidden'>
										{product.code}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
			{additions.length > 0 && (
				<div className='flex flex-col md:flex-row items-center gap-2 mt-8'>
					<Button
						backgroundColor={appColors.gray}
						color='#fff'
						className='flex-1 md:flex-none'
						leftIcon={<DeleteIcon />}
						onClick={clear}
					>
						Limpiar
					</Button>
					<Button
						backgroundColor={appColors.primary}
						color='#fff'
						className='flex-1 md:flex-none'
						leftIcon={<CheckIcon />}
						onClick={() => setShowConfirmModal(true)}
					>
						{loading ? <Spinner color='#fff' boxSize={3} /> : 'Registrar'}
					</Button>
				</div>
			)}
			{showConfirmModal && (
				<Modal
					open={showConfirmModal}
					onClose={() => setShowConfirmModal(false)}
					center
				>
					<div className='flex flex-col gap-8'>
						<p className='text-xl text-[#222] mt-4 pr-8'>
							Vas a registrar {additions.length} adiciones. Estás seguro que
							deseas continuar?
						</p>
						<div className='flex flex-col md:flex-row items-center gap-2 mt-2'>
							<Button
								backgroundColor={appColors.gray}
								color='#fff'
								className='flex-1 md:flex-none'
								leftIcon={<CloseIcon />}
								onClick={() => setShowConfirmModal(false)}
							>
								Cancelar
							</Button>
							<Button
								backgroundColor={appColors.primary}
								color='#fff'
								className='flex-1 md:flex-none'
								leftIcon={<CheckIcon />}
								onClick={createAdditions}
							>
								Registrar
							</Button>
						</div>
					</div>
				</Modal>
			)}
		</MainLayout>
	)
}

export default UploadAdditionsPage
