import { getToken } from 'firebase/messaging'
import { messaging } from './config'
import { env } from '../../config/environment'

export const initMessaging = async () => {
  const permissions =  await Notification.requestPermission()
  if (permissions === 'granted') {
    const token = await getToken(messaging, { vapidKey: env.firebase.vapidKey })
    return token
  } else {
    throw new Error('Permission denied')
  }
};
