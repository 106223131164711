import axios from 'axios'
import { env } from '../../config/environment'

export const fetchCompany = email => {
	return axios.post(`${env.apiUrl}/companies/email`, { email })
}

export const updateCompany = (id, body) => {
	const url = `${env.apiUrl}/companies?ID=${id}`
	return axios.put(url, body)
}

export const updateBanner = (urlBanner, companyId) => {
	return axios.put(
		`${env.apiUrl}/companies/banner?ID=${companyId}`,
		JSON.stringify(urlBanner)
	)
}

export const updateImage = (urlImage, companyId) => {
	return axios.put(
		`${env.apiUrl}/companies/image?ID=${companyId}`,
		JSON.stringify(urlImage)
	)
}

export const fetchStats = companyId => {
	return axios.get(`${env.apiUrl}/companies/stats?company=${companyId}`)
}

export const addDevice = (companyId, device) => {
	return axios.put(
		`${env.apiUrl}/companies/devices/add?company=${companyId}&device=${device}`
	)
}

export const removeDevice = (companyId, device) => {
	return axios.put(
		`${env.apiUrl}/companies/devices/remove?company=${companyId}&device=${device}`
	)
}

export const createCompany = body => {
	return axios.post(`${env.apiUrl}/companies`, body)
}

export const fetchCompaniesByCityState = (city, state) => {
	return axios.post(`${env.apiUrl}/companies/city-state/all`, { city, state })
}

export const countCompaniesByCityState = (city, state) => {
	return axios.post(`${env.apiUrl}/companies/city-state/count`, { city, state })
}

export const fetchCompaniesIdIn = ids => {
	return axios.post(`${env.apiUrl}/companies/id-in`, { ids })
}

export const updateStatus = (companyId, status) => {
	return axios.put(
		`${env.apiUrl}/companies/status?ID=${companyId}&status=${status}`
	)
}

export const updateSchedules = (companyId, schedules) => {
	return axios.put(
		`${env.apiUrl}/companies/schedules?ID=${companyId}`,
		schedules
	)
}
