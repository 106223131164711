import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  partners: [],
}

export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setPartners: (state, action) => {
      state.partners = action.payload
    },
  },
})

export const { setPartners } = partnersSlice.actions

export default partnersSlice.reducer