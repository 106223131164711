import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	companies: [],
	companiesMap: {},
}

export const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {
		setCompanies: (state, action) => {
			state.companies = action.payload
			state.companiesMap = action.payload.reduce((acc, company) => {
				acc[company.id] = company
				return acc
			}, {})
		},
	},
})

export const { setCompanies } = companiesSlice.actions

export default companiesSlice.reducer
