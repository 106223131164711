const AUTH_KEY = 'agus-business@auth'

export const getLocalAuth = () => {
  const auth = sessionStorage.getItem(AUTH_KEY)
  return auth ? JSON.parse(auth) : null
}

export const setLocalAuth = auth => {
  sessionStorage.setItem(AUTH_KEY, JSON.stringify(auth))
}

export const clearLocalAuth = () => {
  sessionStorage.removeItem(AUTH_KEY)
}
