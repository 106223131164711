export const env = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  eventsUrl: process.env.REACT_APP_EVENTS_URL || '',
  socketUrl: process.env.REACT_APP_SOCKET_URL || '',
  processPaymentUrl: process.env.REACT_APP_PROCESS_PAYMENT_URL || '',
  appHeader: process.env.REACT_APP_APP_HEADER || '',
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY || '',
  },
}