import axios from 'axios'
import { env } from '../../config/environment'

export const fetchPaymentsByCompany = body => {
  return axios.post(`${env.apiUrl}/payments/period`, body)
}

export const fetchPendingPayments = (partner, types) => {
  const typeQuery = types.map(type => `type=${type}`).join('&')
  return axios.get(`${env.apiUrl}/payments/partner/pending?partner=${partner}&${typeQuery}`)
}

export const fetchPendingCommissionsByCompany = (company, amount) => {
  return axios.get(`${env.apiUrl}/payments/pending/company?company=${company}&amount=${amount}`)
}

export const fetchPendingCommissionsByRider = (rider, amount) => {
  return axios.get(`${env.apiUrl}/payments/pending/company?company=${rider}&amount=${amount}`)
}

export const makePayments = ids => {
  return axios.post(`${env.apiUrl}/payments/pay`, { ids })
}