import moment from 'moment'
import { PAYMENTS_STATUS } from '../../utils/status'
import { useSelector } from 'react-redux'
import { formatAmount } from '../../utils/currency'

const PaymentItem = ({ payment, company, paymentButton = false, handlePaymentModal }) => {
	const { type } = useSelector(state => state.auth)

	const getAmountText = () => {
		if (type === 'partner' && payment?.amount < 0) {
			return <p className='text-[17px] font-bold text-red-500'>${formatAmount(Math.abs(payment.amount))}</p>
		}
		if (type === 'partner' && payment?.amount > 0) {
			return <p className='text-[17px] font-bold text-green-500'>${formatAmount(Math.abs(payment.amount))}</p>
		}
		if (type === 'company' && payment?.amount < 0) {
			return <p className='text-[17px] font-bold text-green-500'>${formatAmount(Math.abs(payment.amount))}</p>
		}
		if (type === 'company' && payment?.amount > 0) {
			return <p className='text-[17px] font-bold text-red-500'>${formatAmount(Math.abs(payment.amount))}</p>
		}
	}

	return (
		<div
			key={payment.id}
			className='flex flex-col items-start justify-between md:items-center md:flex-row p-2 shadow-sm rounded-md'
		>
			<div className='flex flex-col'>
				<p className='font-bold text-[14px]'>{payment.description}</p>
				<p className='font-italic text-[12px]'>
					{moment(payment._created)
						.subtract(new Date().getTimezoneOffset(), 'minutes')
						.format('DD MMM YYYY')}
				</p>
				<div
					className={`w-[160px] flex items-center justify-center p-1 rounded-md ${payment.status === 'pending' ? 'bg-[#a0a0a0]' : 'bg-green-500'
						}`}
				>
					<span className='text-[11px] font-bold text-white'>
						{PAYMENTS_STATUS[payment.status].text}
					</span>
				</div>
				{company && <p className='font-italic text-[12px]'>
					{company?.name}
				</p>}
			</div>
			<div className="flex flex-col items-center">
				{getAmountText()}
				{paymentButton && payment.status === 'pending' && <button className='bg-transparent text-[#37a] text-[14px]' onClick={() => handlePaymentModal(payment)}>Pagar</button>}
			</div>
		</div>
	)
}

export default PaymentItem