export const STATUS = {
  'new': 'Nuevo',
  'pending': 'Pendiente',
  'processing': 'En progreso',
  'on-the-way': 'En camino',
  'delivered': 'Entregado',
  'rejected': 'Rechazado',
  'waiting': 'Esperando domiciliario'
}

export const STATUS_COLORS = {
  'new': '#000',
  'pending': '#a0a0a0',
  'processing': '#5cb85c',
  'on-the-way': '#5bc0de',
  'delivered': '#f05112',
  'rejected': '#000',
  'waiting': '#DBD832'
}

export const RIDER_STATUS = {
  'available': { text: 'Disponible', color: '#5cb85c' },
  'busy': { text: 'Ocupado', color: '#f05112' },
  'offline': { text: 'No disponible', color: '#a0a0a0' },
}

export const PAYMENTS_STATUS = {
  'paid': { text: 'Pagado', color: '#5cb85c' },
  'pending': { text: 'Pendiente de pago', color: '#a0a0a0' },
}

export const PAYMENT_METHODS = {
  'CASH': { text: 'Efectivo', color: '#5cb85c' },
  'CARD': { text: 'Tarjeta', color: '#f05112' },
  'BANCOLOMBIA_TRANSFER': { text: 'Bancolombia', color: '#5bc0de' },
  'PSE': { text: 'PSE', color: '#5bc0de' },
  'NEQUI': { text: 'Nequi', color: '#5bc0de' },
}