import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  selectedOrder: null,
  orders: [],
  errands: [],
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    selectOrder: (state, action) => {
      state.selectedOrder = action.payload
    },
    setOrders: (state, action) => {
      state.orders = action.payload
    },
    addOrder: (state, action) => {
      const newOrders = state.orders.filter(order => order.id !== action.payload.id)
      state.orders = [action.payload, ...newOrders]
    },
    setErrands: (state, action) => {
      state.errands = action.payload
    },
    addErrand: (state, action) => {
      const newErrands = state.errands.filter(errand => errand.id !== action.payload.id)
      state.errands = [action.payload, ...newErrands]
    },
    updateOrder: (state, action) => {
      const { id, ...data } = action.payload
      state.orders = state.orders.map(order => (order.id === id ? { ...order, ...data } : order))
    },
    updateErrand: (state, action) => {
      const { id, ...data } = action.payload
      state.errands = state.errands.map(errand => (errand.id === id ? { ...errand, ...data } : errand))
    },
  },
})

export const { selectOrder, setOrders, addOrder, setErrands, addErrand, updateOrder, updateErrand, } = orderSlice.actions

export default orderSlice.reducer