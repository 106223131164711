import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Button, CircularProgress, Select, Text, useToast } from '@chakra-ui/react'

import MainLayout from '../components/layout/main-layout'
import { appColors } from '../utils/colors'
import { updateSchedules } from '../services/api/company'
import { setLocalAuth } from '../services/app/sessionStorage'

const DAYS = [
	'Lunes',
	'Martes',
	'Miércoles',
	'Jueves',
	'Viernes',
	'Sábado',
	'Domingo',
]

const SchedulesPage = () => {
	const desktop = useSelector(({ mediaQuery }) => !mediaQuery.mobileScreen)
	const company = useSelector(({ auth }) => auth.business)
	const navigate = useNavigate()
	const [day, setDay] = useState(1)
	const [open, setOpen] = useState('')
	const [close, setClose] = useState('')
	const [schedules, setSchedules] = useState([])
	const [loading, setLoading] = useState(false)
  const toast = useToast()

	useEffect(() => {
		setSchedules(company.schedules ?? [])
	}, [company])

	const handleAdd = () => {
    if (!day || !open || !close || open === close) {
      toast({
        title: 'Error',
        description: 'Debes seleccionar día, hora de apertura y cierre',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
		const daySched = schedules.find(s => s.day === day) ?? { schedules: [], day }
		const newSched = {...daySched, schedules: [
      ...daySched.schedules,
      { open, close }
    ]}
		const exists = schedules.find(s => s.day === day)
		let tmpSchedules = schedules.map(s => (s.day === day ? newSched : s))
		if (!exists) {
			tmpSchedules = ([...schedules, newSched])
		}
		setLoading(true)
		updateSchedules(company.id, tmpSchedules)
		.then(() => {
			toast({
				title: 'Horario actualizado',
				description: 'El horario fue actualizado correctamente',
				status: 'success',
				duration: 4000,
				isClosable: true,
			})
			setSchedules(tmpSchedules)
			setOpen('')
			setClose('')
			setDay(1)
			document.getElementById('day-select').value = ''
			document.getElementById('open-time').value = ''
			document.getElementById('close-time').value = ''
			setLocalAuth({ ...company, schedules: tmpSchedules })
		})
		.catch(() => {
			toast({
				title: 'Error',
				description: 'Ocurrió un error al actualizar el horario',
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
		})
		.finally(() => setLoading(false))
	}

	const handleDelete = sched => () => {
		const daySched = schedules.find(s => s.day === day)
		const newSched = {...daySched, schedules: daySched.schedules.filter(
			s => s.open !== sched.open || s.close !== sched.close
		)}
		const tmpSchedules = schedules.map(s => (s.day === day ? newSched : s))
		setLoading(true)
		updateSchedules(company.id, tmpSchedules)
		.then(() => {
			toast({
				title: 'Horario actualizado',
				description: 'El horario fue actualizado correctamente',
				status: 'success',
				duration: 4000,
				isClosable: true,
			})
			setSchedules(tmpSchedules)
			setLocalAuth({ ...company, schedules: tmpSchedules })
		})
		.catch(() => {
			toast({
				title: 'Error',
				description: 'Ocurrió un error al actualizar el horario',
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
		})
		.finally(() => setLoading(false))
	}

	return (
		<MainLayout>
			{desktop && (
				<div className='flex flex-row items-center h-[30px] mb-5 ml-[-20px] mt-[-10px]'>
					<button onClick={() => navigate(-1)}>
						<ChevronLeftIcon color='black' boxSize={8} /> Mi negocio
					</button>
				</div>
			)}
			<div className='flex flex-col mb-8'>
				<h1 className='text-3xl font-bold'>Horarios</h1>
				<p className='text-gray-500'>
					Establece los horarios de atención de tu negocio
				</p>
			</div>
			<div className='flex flex-row items-center gap-2'>
				<div className='flex-1 flex flex-col gap-1'>
					<Text as='b'>Día de la semana</Text>
					<Select
						placeholder='Select option'
						onChange={evt => setDay(evt.target.value)}
						id='day-select'
					>
						{DAYS.map((day, i) => (
							<option value={`${i + 1}`} key={day}>
								{day}
							</option>
						))}
					</Select>
				</div>
				<div className='flex-1 flex flex-col gap-1'>
					<Text as='b'>Hora de apertura</Text>
					<input
						type='time'
						onChange={evt => setOpen(evt.target.value)}
						className='bg-transparent border border-color-[#999] py-1 px-2'
						id='open-time'
					/>
				</div>
				<div className='flex-1 flex flex-col gap-1'>
					<Text as='b'>Hora de cierre</Text>
					<input
						type='time'
						onChange={evt => setClose(evt.target.value)}
						className='bg-transparent border border-color-[#999] py-1 px-2'
						id='close-time'
					/>
				</div>
				<div className='flex-1 flex flex-col gap-1 mt-8'>
					<Button
						backgroundColor={appColors.primary}
						leftIcon={!loading && <AddIcon color='#fff' />}
						onClick={handleAdd}
						color='#fff'
						disabled={loading}
					>
						{loading ? (
							<CircularProgress isIndeterminate color='white' size='30px' />
						) : (
							'Agregar'
						)}
					</Button>
				</div>
			</div>
			<div className='flex flex-col gap-4 mt-8'>
				{schedules
					?.find(sched => sched.day === day)
					?.schedules.map((sched, index) => (
						<div className='flex flex-row items-center gap-2' key={index}>
							<div className='flex flex-col md:flex-row gap-3 md:gap-5'>
								<Text>
									De <strong>{sched.open}</strong>
								</Text>
								<Text>
									A <strong>{sched.close}</strong>
								</Text>
							</div>
							<div className='cursor-pointer' onClick={handleDelete(sched)}>
								<DeleteIcon color='#f00' />
							</div>
						</div>
					))}
			</div>
		</MainLayout>
	)
}

export default SchedulesPage
