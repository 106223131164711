import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Header from './header'
import { Sidebar, SidebarWithBackdrop } from './sidebar'
import { fetchCategories } from '../../services/api/categories'
import { setCategories } from '../../redux/features/categoriesSlice'

import './main-layout.css'
import { fetchCompaniesByCityState } from '../../services/api/company'
import { setCompanies } from '../../redux/features/companiesSlice'

const MainLayout = ({ children, inner, title }) => {
	const mobileScreen = useSelector(({ mediaQuery }) => mediaQuery.mobileScreen)
	const navbarExpanded = useSelector(({ navbar }) => navbar.expanded)
	const { business: company, type } = useSelector(({ auth }) => auth)
	const { companies } = useSelector(({ companies }) => companies)
	const dispatch = useDispatch()

	useEffect(() => {
		fetchCategories().then(res => {
			dispatch(setCategories(res.data))
		})
	}, [])

	useEffect(() => {
		if (type === 'partner' && companies.length === 0) {
			fetchCompaniesByCityState(company.city, company.state).then(res => {
				dispatch(setCompanies(res.data))
			})
		}
	}, [company])

	return (
		<div className='main-layout-container w-screen h-screen flex flex-row'>
			{mobileScreen ? <SidebarWithBackdrop /> : <Sidebar />}
			<Header inner={inner} title={title} />
			<div
				className={`main-layout-content w-full h-full mt-[70px] p-[32px] sm:p-4 overflow-scroll ${
					!mobileScreen && navbarExpanded ? 'content-expanded' : ''
				}`}
			>
				{type === 'company' && !company.open && (
					<div className='flex w-full bg-[#d9534f] rounded-lg mb-8'>
						<p className='text-lg font-bold text-white p-4'>
							Tu negocio se encuentra cerrado. No podrás recibir pedidos hasta
							que actualices su estado.
						</p>
					</div>
				)}
				{children}
			</div>
		</div>
	)
}

export default MainLayout
